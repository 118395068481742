import ImageOne  from '../image/Image1.jpg'
import ImageTwo from '../image/Image2.jpg'
import ImageThree from '../image/Image3.jpg'
import ImageFour from '../image/Image4.jpg'

export const SliderData = [
    {
        title: 'support a causes you care about',
        info: "I'm a info. go to SliderData.js add your own text and edit me",
        path: "/home",
        label: 'view causes',
        image: ImageTwo,
        alt:'children'
    },
    {
        title: 'support a causes you care about',
        info: "I'm a info. go to SliderData.js add your own text and edit me",
        path: "/home",
        label: 'view causes',
        image: ImageOne,
        alt:'children'
    },
    {
        title: 'support a causes you care about',
        info: "I'm a info. go to SliderData.js add your own text and edit me",
        path: "/home",
        label: 'view causes',
        image: ImageThree,
        alt:'children'
    },
    {
        title: 'support a causes you care about',
        info: "I'm a info. go to SliderData.js add your own text and edit me",
        path: "/home",
        label: 'view causes',
        image: ImageFour,
        alt:'children'
    }
]

