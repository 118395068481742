export const menuData = [
    {
        title: 'Home',
        link: 'home'
    },
    {
        title: 'About',
        link: 'about'
    },
    {
        title: 'Causes',
        link: 'causes'
    },
    {
        title: 'Footer',
        link: 'footer'
    }
]